<template>
  <q-card class="advertiser-reporting-overview-monitor">
    <q-card-section class="flex items-center justify-between">
      <h6>Performance Overview</h6>
      <div class="flex no-wrap items-center q-gutter-x-md">
        <!--<q-btn
          class="q-pa-sm"
          :class="{ 'cursor-not-allowed': loading }"
          unelevated
          icon="sym_r_refresh"
          flat
          color="grey-6"
          text-color="grey-7"
          size="md"
          @click="generateLineCharts()"
        >
          <q-tooltip anchor="top middle" self="bottom middle">
            Refresh
          </q-tooltip>
        </q-btn>-->
        <DateRangeSelector
          :initial-start-date="startDate"
          :initial-end-date="endDate"
          initial-preset="Today"
          @update="
            dates =>
              updateDateRange(dates) /* && $emit('page-date-range-update')*/
          "
        />
      </div>
    </q-card-section>

    <!-- TODO: Convert below into TabsCard? -->
    <q-tabs
      v-model="selectedMetric"
      align="justify"
      indicator-color="primary"
      switch-indicator
      no-caps
      class="advertiser-reporting-overview-monitor__tabs"
    >
      <q-tab
        v-for="metricOption in metricOptions"
        :key="metricOption.value + '_OverviewMonitorMetric'"
        :name="metricOption.value"
        class="advertiser-reporting-overview-monitor__tab"
      >
        <template v-slot>
          <div class="text-left q-my-sm">
            <Label
              :text="metricOption.label"
              size="large"
              :class="{ 'text-primary': selectedMetric === metricOption.value }"
              class="q-mb-sm"
              underlined
            />
            <div
              v-if="loading || Object.keys(dataByMetric).length === 0"
              :style="
                selectedMetric !== metricOption.value ? 'opacity: 0.7' : ''
              "
            >
              <q-skeleton type="text" style="width: 75px" />
            </div>
            <div
              v-else
              class="flex items-end"
              :style="
                selectedMetric !== metricOption.value ? 'opacity: 0.7' : ''
              "
            >
              <h3>{{ dataByMetric[metricOption.value].displayMetric }}</h3>
              <!--<small
                :class="
                  metricOption.trendValue.charAt(0) === '-'
                    ? 'text-negative'
                    : 'text-positive'
                "
                class="q-ml-sm"
              >
                <q-icon
                  :name="
                    metricOption.trendValue.charAt(0) === '-'
                      ? 'sym_r_trending_down'
                      : 'sym_r_trending_up'
                  "
                  class="q-mr-xs"
                />
                <span class="text-weight-medium">{{ metricOption.trendValue }}</span>
              </small>-->
            </div>
          </div>
        </template>
      </q-tab>
    </q-tabs>

    <q-card-section
      class="flex justify-center items-center"
      style="min-height: 200px"
    >
      <Loader v-if="loading" />
      <template v-else-if="Object.keys(dataByMetric).length > 0">
        <template
          v-for="metricKey in Object.keys(dataByMetric)"
          :key="'AdvertiserOverviewLineChart_' + metricKey"
        >
          <LineChart
            v-if="
              metricKey == selectedMetric &&
                dataByMetric[metricKey].lineChartData.dataSeries[0].data
                  .length > 0
            "
            x-axis-type="time"
            :data-series="dataByMetric[metricKey].lineChartData.dataSeries"
            height="200px"
          />
          <NoDataAvailable
            v-else-if="
              metricKey == selectedMetric &&
                dataByMetric[metricKey].lineChartData.dataSeries[0].data
                  .length === 0
            "
          />
        </template>
      </template>
    </q-card-section>

    <q-card-section class="flex justify-end items-center q-pt-none">
      <a
        @click="
          () =>
            $router.push({
              name: 'ReportingOverview',
              query: { start_date: this.startDate, end_date: this.endDate }
            })
        "
        class="flex items-center"
      >
        <small>Go to Reporting</small>
        <q-icon size="1.2em" name="sym_r_chevron_right" color="primary" />
      </a>
    </q-card-section>
  </q-card>
</template>

<script>
import LineChart from "@/components/UI/LineChart";
import Label from "@/components/UI/Label";
import DateRangeSelector from "@/components/UI/DateRangeSelector";
import Loader from "@/components/UI/Loader";
import ComponentsMixin from "@/mixins/ComponentsMixin";
import NoDataAvailable from "@/components/UI/NoDataAvailable";

// let initialUrlParams = new URLSearchParams(window.location.search);

export default {
  name: "AdvertiserReportingOverviewMonitor",
  mixins: [ComponentsMixin],
  components: { NoDataAvailable, Loader, DateRangeSelector, Label, LineChart },
  props: {},
  data() {
    return {
      loading: false,
      startDate: this.$dayjs()
        .tz()
        .format("YYYY-MM-DD"),
      endDate: this.$dayjs()
        .tz()
        .format("YYYY-MM-DD"),
      selectedMetric: "impressions",
      metricOptions: [
        {
          label: "Spend",
          value: "spend"
        },
        {
          label: "Impressions",
          value: "impressions"
        },
        {
          label: "Clicks",
          value: "clicks"
        },
        {
          label: "CTR",
          value: "ctr"
        },
        {
          label: "CPM",
          value: "cpm"
        },
        {
          label: "CPC",
          value: "cpc"
        }
      ],
      dataByMetric: {}
    };
  },
  computed: {},
  mounted() {
    this.generateLineCharts();
  },
  methods: {
    updateDateRange(dates = null) {
      if (dates) {
        this.startDate = dates.startDate;
        this.endDate = dates.endDate;
      }

      // this.addUrlParamsToWindow([ { name: "start_date", value: this.startDate }, { name: "end_date", value: this.endDate }]);
      // this.saveStateToUrl("adrom", { startDate: this.startDate, endDate: this.endDate });

      this.generateLineCharts();
    },
    generateLineCharts() {
      this.loading = true;

      this.$store
        .dispatch("advertiserReporting/POST_REQUEST", {
          params: {
            report_payload: {
              start_date: this.startDate,
              end_date: this.endDate,
              options: {
                include_time_last_updated: true,
                format_results: false,
                format_totals: true,
                enable_drilldown_mode: false,
                include_totals: true,
                disable_caching: true,
                dimension_key_indexed_results: false,
                sorting: [
                  { field: "date", direction: "asc" },
                  { field: "hour", direction: "asc" }
                ]
              },
              dimensions: {
                date: [],
                hour: []
              },
              metrics: {
                spend: [],
                impressions: [],
                clicks: [],
                ctr: [],
                cpm: [],
                cpc: []
              }
            }
          }
        })
        .then(report => {
          let totals =
            "metadata" in report && "totals" in report.metadata
              ? report.metadata.totals
              : {};
          Object.keys(totals).forEach(metricKey => {
            this.dataByMetric[metricKey] = {
              lineChartData: {
                labels: [],
                dataSeries: []
              },
              displayMetric: totals[metricKey],
              trendValue: "0.00%"
            };
          });

          let rows = "rows" in report ? report.rows : [];
          rows.forEach(row => {
            Object.keys(this.dataByMetric).forEach(metricKey => {
              this.dataByMetric[metricKey].lineChartData.dataSeries.push([
                new Date(row.date + " " + row.hour + ":00:00"),
                row[metricKey]
              ]);
            });
          });
          Object.keys(this.dataByMetric).forEach(metricKey => {
            let data = this.dataByMetric[metricKey].lineChartData.dataSeries;
            this.dataByMetric[metricKey].lineChartData.dataSeries = [
              { name: metricKey, data: data }
            ];
          });

          this.loading = false;
        });
    }
  }
};
</script>

<style scoped lang="scss"></style>
